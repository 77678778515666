// Generated by Framer (e83dae3)

import { addFonts, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./CwLOzdRPu-0.js";

const cycleOrder = ["z0y8eLQPx"];

const serializationHash = "framer-ACkwS"

const variantClassNames = {z0y8eLQPx: "framer-v-1p8owz5"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {QqP3jQNpQ: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "z0y8eLQPx", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={{webPageId: "FIx4NOFX_"}} openInNewTab={false}><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1p8owz5", className, classNames)} framer-1fz2blm`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"z0y8eLQPx"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7T3V0Zml0LTYwMA==", "--framer-font-family": "\"Outfit\", \"Outfit Placeholder\", sans-serif", "--framer-font-size": "18px", "--framer-font-weight": "600", "--framer-text-color": "var(--extracted-r6o4lv, rgb(242, 242, 242))"}}>Holovenko </motion.p></React.Fragment>} className={"framer-1wjmyiv"} fonts={["GF;Outfit-600"]} layoutDependency={layoutDependency} layoutId={"qnAhpk1te"} style={{"--extracted-r6o4lv": "rgb(242, 242, 242)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/><motion.div className={"framer-9rk2tv"} layoutDependency={layoutDependency} layoutId={"rLd730kDE"} style={{backgroundColor: "rgb(242, 242, 242)", borderBottomLeftRadius: 2, borderBottomRightRadius: 2, borderTopLeftRadius: 2, borderTopRightRadius: 2}}/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ACkwS.framer-1fz2blm, .framer-ACkwS .framer-1fz2blm { display: block; }", ".framer-ACkwS.framer-1p8owz5 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 30px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-ACkwS .framer-1wjmyiv { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-ACkwS .framer-9rk2tv { bottom: 6px; flex: none; height: 4px; overflow: visible; position: absolute; right: 0px; width: 4px; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-ACkwS.framer-1p8owz5 { gap: 0px; } .framer-ACkwS.framer-1p8owz5 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-ACkwS.framer-1p8owz5 > :first-child { margin-left: 0px; } .framer-ACkwS.framer-1p8owz5 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 30
 * @framerIntrinsicWidth 89.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerCwLOzdRPu: React.ComponentType<Props> = withCSS(Component, css, "framer-ACkwS") as typeof Component;
export default FramerCwLOzdRPu;

FramerCwLOzdRPu.displayName = "Logo";

FramerCwLOzdRPu.defaultProps = {height: 30, width: 89.5};

addFonts(FramerCwLOzdRPu, [{family: "Outfit", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/outfit/v11/QGYyz_MVcBeNP4NjuGObqx1XmO1I4e6yC4S-EiAou6Y.woff2", weight: "600"}])